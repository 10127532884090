import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { TailSpin } from  'react-loader-spinner';

//API
import api from '../../../settings/AxiosSetup';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import InfoBlock from '../../../components/dashboard/infoblock';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './dashboard.css';
import 'react-loading-skeleton/dist/skeleton.css';

const Dashboard = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const [offertes, setOffertes] = useState('');
    const [allGoedgekeurd, setAllGoedgekeurd] = useState(0);
    const [document, setDocument] = useState('');
    const [searchText, setSearchText] = useState(localStorage.getItem("searchText") ? localStorage.getItem("searchText") : '');
    const [searchResults, setSearchResults] = useState([]);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);

    //Function to get medewerker info
    const getMedewerker = async () => {
        try {
            const response = await api.get('/medewerkers/me');
            if (response.data) {
                if (!medewerker) {
                    setMedewerker(response.data);
                }
                localStorage.setItem("medewerker", JSON.stringify(response.data));
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const getSearchResults = async (event) => {
        event.preventDefault();
        setLoadingSearchResults(true);
        try {
            const response = await api.post('/offertes/zoeken', {
                data: {
                    value: searchText
                }
            });
            if (response.data) {
                setSearchResults(response.data);
                localStorage.setItem("searchText", searchText);
                setLoadingSearchResults(false);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const getSearchResultsInitial = async () => {
        setLoadingSearchResults(true);
        try {
            const response = await api.post('/offertes/zoeken', {
                data: {
                    value: searchText
                }
            });
            if (response.data) {
                setSearchResults(response.data);
                setLoadingSearchResults(false);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    //Function to get medewerker info
    const getOffertes = async () => {
        try {
            const response = await api.get('/offertes/offertes-dashboard');
            if (response.data) {
                const excludedKeys = ['afgerond', 'afgewezen', 'in_afwachting', 'in_afwachting_verzenden', 'openstaand'];
                let sum = 0;
                for (const key in response.data) {
                    if (!excludedKeys.includes(key)) {
                        sum += response.data[key];
                    }
                }
                setAllGoedgekeurd(sum);
                setOffertes(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getMedewerker();
        getOffertes();
        if (searchText) {
            getSearchResultsInitial();
        }
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/" />
            :
            null
        }
        
        <div className="dashboard-content">
            {
                medewerker ?
                <>
                <DashboardHeader title={'Welkom ' + medewerker.voornaam} />
                    {
                        medewerker && medewerker.gebruiker.role.type === 'authenticated' ?
                        <div className="info-blocks three">
                            <InfoBlock icon={<Icon.Receipt color={'#001E4E'} width={50} height={50} />} title={'Openstaande offertes'} data={offertes} count={offertes.openstaand} page={'/openstaand'} />
                            <InfoBlock icon={<Icon.ClockHistory color={'#BB0024'} width={50} height={50} />} title={'Offertes in afwachting'} data={offertes} count={offertes.in_afwachting} page={'/in-afwachting'} />
                            <InfoBlock icon={<Icon.CheckCircle color={'#00D100'} width={50} height={50} />} title={'Goedgekeurde offertes'} data={offertes} count={allGoedgekeurd} page={'/goedgekeurd'} />
                        </div>
                        :
                        null
                    }
                    <div className="info-blocks two">
                        <InfoBlock icon={<Icon.ClockHistory color={'#FFA500'} width={50} height={50} />} title={'Offertes te verwerken'} data={offertes} count={offertes.te_verwerken} page={'/te-verwerken'} />
                        {
                            medewerker && medewerker.gebruiker.role.type === 'authenticated' ?
                            <InfoBlock icon={<Icon.CheckCircleFill color={'#00D100'} width={50} height={50} />} title={'Offertes volledig verwerkt'} data={offertes} count={offertes.afgerond} page={'/afgerond'} />
                            :
                            null
                        }
                    </div>
                    {
                    medewerker && medewerker.gebruiker.role.type === 'authenticated' ?
                    <div className="search-offertes">
                        <form onSubmit={getSearchResults}>
                            <div className="icon">
                                {
                                    !loadingSearchResults ?
                                    <Icon.Search color="#757575" size={20} className="search" />
                                    :
                                    <div className="loading"><TailSpin color="#001C43" width={30} /></div>
                                }
                            </div>
                            <input type="text" placeholder="Zoeken op klant of offertenummer" className="search-field" value={searchText} onChange={(e) => setSearchText(e.target.value)} required />
                        </form>
                        <div className="offerte-results">
                            {
                                searchResults.length > 0 ?
                                <>
                                {
                                    searchResults.map((item, index) =>  {
                                        console.log(item);
                                        let status = item.status.replace(/_/g, ' ');
                                        let finalStatus = status.charAt(0).toUpperCase() + status.slice(1);
                                        if (item.status === 'in_afwachting') {
                                            var page = 'in-afwachting';
                                        } else if (item.status === 'in_afwachting_verzenden') {
                                            var page = 'te-controleren';
                                        } else if (item.status === 'debiteuren' || item.status === 'tariefinrichting' || item.status === 'sales' || item.status === 'marketing') {
                                            var page = 'goedgekeurd';
                                        } else {
                                            var page = item.status;
                                        }
                                        return <a key={index} href={'/' + page + '?search=' + item.offertenummer} className="list-item">
                                                    <div className="item-content">
                                                        <div className="item-info">
                                                            <div className="item-title">
                                                                <p>{item.offertenummer}</p>
                                                                <p>{item.klant.bedrijfsnaam}</p>
                                                            </div>
                                                            <div className="item-meta">
                                                                {/* <p>{item.divisie.label}</p> */}
                                                                <p style={{minWidth:250}}>{item.soort_offerte ? item.soort_offerte.naam : ''}</p>
                                                                {item.vestiging ? <p>{item.vestiging.naam}</p> : null}
                                                                <p>{moment(item.geldig_van).year()}</p>
                                                                {item.verzonden_op ? <p>Verzonden op: {moment(item.verzonden_op).format('DD-MM-YYYY')}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="item-actions">
                                                            <p>{finalStatus}{item.status === 'in_afwachting_verzenden' ? ' (' + item.aangemaakt_voor.voornaam + ')' : null}</p>
                                                            <button>Bekijken</button>
                                                        </div>
                                                    </div>
                                                </a>
                                    })
                                }
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    null
                    }
                </>
                :
                null
            }
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/" />
            :
            null
        }
    {/* <img src={vsdvArtwork} className="artwork" />     */}
    </div>
    );
};
export default Dashboard;