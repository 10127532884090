import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//3rd party
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import * as Icon from 'react-bootstrap-icons';

//styles
import './sidenav.css';

//Assets
import vsdvLogo from '../../assets/images/logo.svg';
import svzLogo from '../../assets/images/svz-logo-red.svg';

const SideNav = (props) => {

    //Declare variables
    const navigate = useNavigate();
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const page = props.page;
    const [day, setDay] = useState(moment().format('dddd D MMM YYYY'));
    const [time, setTime] = useState(moment().format('HH:mm'));
    //Set navitems based on current user role
    const navitems = [
        {
            name: 'Dashboard',
            link: '/',
            icon: <Icon.House />,
            roles: ['authenticated', 'debiteuren', 'tariefinrichting', 'debiteuren_en_tariefinrichting', 'marketing', 'administratie', 'super_admin']
        },
        {
            name: 'Offertes',
            link: null,
            icon: <Icon.ReceiptCutoff />,
            roles: ['authenticated', 'debiteuren', 'tariefinrichting', 'debiteuren_en_tariefinrichting', 'marketing', 'administratie', 'super_admin'],
            subitems: [
                {
                    name: 'Nieuwe offerte',
                    link: '/offerte',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'super_admin']
                },
                {
                    name: 'Openstaand',
                    link: '/openstaand',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'super_admin']
                },
                {
                    name: 'Te controleren',
                    link: '/te-controleren',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'super_admin']
                },
                {
                    name: 'In afwachting',
                    link: '/in-afwachting',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'super_admin']
                },
                {
                    name: 'Afgewezen',
                    link: '/afgewezen',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'super_admin']
                },
                {
                    name: 'Goedgekeurd',
                    link: '/goedgekeurd',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'tariefinrichting', 'super_admin']
                },
                {
                    name: 'Te verwerken',
                    link: '/te-verwerken',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'debiteuren', 'tariefinrichting', 'debiteuren_en_tariefinrichting', 'marketing', 'super_admin']
                },
                {
                    name: 'Afgerond',
                    link: '/afgerond',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'tariefinrichting', 'debiteuren_en_tariefinrichting', 'administratie', 'super_admin']
                },
                {
                    name: 'Voorbladen',
                    link: '/voorbladen',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'super_admin']
                }
            ]
        },
        {
            name: 'Gearchiveerd',
            link: '/gearchiveerd',
            icon: <Icon.Archive />,
            roles: ['authenticated', 'super_admin']
        },
        {
            name: 'Rapportage',
            link: '/rapportage',
            icon: <Icon.BarChart />,
            roles: ['authenticated', 'super_admin']
        },
        {
            name: 'Instellingen',
            link: '/instellingen',
            icon: <Icon.Gear />,
            roles: ['authenticated', 'debiteuren', 'tariefinrichting', 'debiteuren_en_tariefinrichting', 'marketing', 'administratie', 'super_admin']
        }
    ];

    //Function to logout from portal
    const logout = () => {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('ingelogd');
        localStorage.removeItem('medewerker');
        localStorage.removeItem('videotutorials');
        localStorage.removeItem('searchText');
        navigate('/login');
    }

    useEffect(() => {
        setInterval(function () {
          setTime(moment().format('HH:mm'));
        }, 10000);
      }, []);

    return (
        <div id="sidenav">
            <div className="nav-header">
                <img src={svzLogo} alt="van Straalen de Vries" />
                <div className="datetime">
                    <p className="day">{day}</p>
                    <p className="time">{time}</p>
                </div>
                {/* <div className="menu-items">
                    {
                        navitems.map((item, index) =>  {
                            if (item.roles.includes(medewerker.gebruiker.role.type)) {
                                return (
                                    <Link key={index} to={item.link} className={page === item.link ? 'item current' : 'item'}>{item.icon}<span>{item.name}</span></Link>
                                )
                            }
                        })
                    }
                </div> */}


                <div className="menu-items">
                    <Accordion>
                    {
                        navitems.map((item, index) =>  {
                            if (item.roles.includes(medewerker.gebruiker.role.type) && item.subitems) {
                                const open = item.subitems.findIndex(item => item.link === page);
                                return (
                                    <AccordionItem key={index} isActive={open > -1}>
                                        <AccordionHeader className={open > -1 ? 'top item active' : 'top item'}>
                                            {item.icon}{item.name}
                                        </AccordionHeader>
                                        <AccordionBody>
                                            {
                                                item.subitems.map((subitem, index) =>  {
                                                    if (subitem.roles.includes(medewerker.gebruiker.role.type)) {
                                                        return (
                                                            <Link key={index} to={subitem.link} className={page === subitem.link ? 'item subitem current' : 'item subitem'}>{subitem.icon}<span>{subitem.name}</span></Link>
                                                        )
                                                    }
                                                })
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                )
                            }
                            if (item.roles.includes(medewerker.gebruiker.role.type)) {
                                return (
                                    <Link key={index} to={item.link} className={page === item.link ? 'item current' : 'item'}>{item.icon}<span>{item.name}</span></Link>
                                )
                            }
                        })
                    }
                    </Accordion>
                </div>

                <div className="bottom">
                    <p className="logout" onClick={logout}><Icon.BoxArrowLeft /> Uitloggen</p>
                </div>
            </div>
        </div>
    );
};
export default SideNav;